@import "@/scss/_variables.scss";









































.load {
  grid-column-gap: 32px;

  @media (max-width: $width-tablet) {
    grid-template-columns: 1fr !important;
    grid-row-gap: 24px;
  }

  .spacing {
    margin-bottom: 24px;
  }
}
