@import "@/scss/_variables.scss";
















































.input-number-container {
  width: 100%;

  h6 {
    margin-bottom: 4px;
  }

  .info {
    margin-top: 8px;

    @media (max-width: $width-tablet) {
      font-size: 0.75rem;
      line-height: initial;
    }
  }
}
